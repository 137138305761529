import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ClassOne from "./ClassOne";
import ClassTwo from "./ClassTwo";
import 'react-tabs/style/react-tabs.css';
import imageMap from './utils/imageMap';
import announcementsByRoom from './data/announcements.json';


// 格式化日期為 "MM/DD (<i>星期</i>)"
const formatDateWithDay = (dateStr) => {
    const date = new Date(dateStr);
    const days = ["日", "一", "二", "三", "四", "五", "六"];
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 補零
    const day = String(date.getDate()).padStart(2, "0"); // 補零
    const dayOfWeek = days[date.getDay()];
    return (
        <b>
            {month}/{day} <i>({dayOfWeek})</i>
        </b>
    );
};


function Class() {
    const [selectedRoom, setSelectedRoom] = useState("光復館");

    const getValidAnnouncements = (roomName) => {
        const now = new Date();
        const announcements = announcementsByRoom[roomName] || [];

        // 篩選有效公告並按日期排序（升序）
        return announcements
            .filter(({ date }) => {
                const endOfDay = new Date(date);
                endOfDay.setHours(23, 59, 59, 999);
                return endOfDay >= now; // 只保留當前時間之後的公告
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // 按日期升序排序
    };

    const selectRoomValue = getValidAnnouncements(selectedRoom);
    return (
        <div className="inner">
            <Tabs onSelect={(index) => {
                // 更新選擇的教室
                const roomNames = ["光復館", "公館館"];
                setSelectedRoom(roomNames[index]);
            }}>
                <div id="blockName">
                    <h5 className="title">課程介紹</h5>
                    <TabList>
                        <Tab>光復館</Tab>
                        <Tab>公館館</Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <div className="left">
                        <h2><b>代停課</b>公告</h2>
                        <ul>
                            {selectRoomValue.length > 0 ? (
                                selectRoomValue.map((item, index) => (
                                    <li key={`${item.date}-${item.text}-${index}`}>
                                        {formatDateWithDay(item.date)}  {item.text}
                                    </li>
                                ))
                            ) : (
                                <li className="noStopClass">目前沒有代停課公告</li>
                            )}
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="level">
                            <li><b className="started">基礎課程</b></li>
                            <li><b className="normal">一般課程</b></li>
                            <li><b className="adv">進階課程</b></li>
                        </ul>
                        <table className="classTable">
                            <colgroup>
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                                <col width="6.6666%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th rowSpan="2"></th>
                                    <th colSpan="2">一</th>
                                    <th colSpan="2">二</th>
                                    <th colSpan="2">三</th>
                                    <th colSpan="2">四</th>
                                    <th colSpan="2">五</th>
                                    <th colSpan="2">六</th>
                                    <th colSpan="2">日</th>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colSpan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>16:30<br />|<br />18:00</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div className="time">18:30 ~ 19:30</div>
                                        <h2 className="started">基礎律動</h2>
                                        <h4>胡婷</h4>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>

                                    </td>
                                    <td colspan="4" rowspan="3" style={{ textAlign: "center", verticalAlign: "middle", padding: "40px 0", fontSize: "15px", lineHeight: "1.6em" }}>
                                        非課程時段<br />
                                        開放場地租借<br />
                                        - 請於上課時段來電洽詢 -
                                    </td>
                                </tr>
                                <tr>
                                    <th>18:30<br />|<br />20:00</th>
                                    <td>
                                        <h2 className="adv">House</h2>
                                        <h4>小穎</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Waacking</h2>
                                        <h4>金瑋瑋</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">High Heels</h2>
                                        <h4>盈蓁</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Hip-Hop</h2>
                                        <h4>Mark</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Afro Freestyle</h2>
                                        <h4>小姬</h4>
                                    </td>
                                    <td>
                                        <div className="time">19:40 ~ 20:40</div>
                                        <h2 className="started">Street Jazz</h2>
                                        <h4>小俐</h4>
                                    </td>
                                    <td>
                                        <h2 className="adv">FreeStyle</h2>
                                        <h4>卡拉</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Girl Style</h2>
                                        <h4>冉宥</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Hip-Hop</h2>
                                        <h4>育玄</h4>
                                    </td>
                                    <td><h2 className="started">House</h2>
                                        <h4>牛牛</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <th>20:15<br />|<br />21:45</th>
                                    <td>
                                        <h2 className="adv">Hip-Hop</h2>
                                        <h4>Kaku</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Dancehall<br />Female</h2><h4>涵涵</h4>
                                    </td>
                                    <td>
                                        <h2 className="adv">Dancehall</h2>
                                        <h4>小姬</h4>
                                    </td>
                                    <td>
                                        <h2 className="adv">Hip-Hop</h2>
                                        <h4>查理</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Popping</h2>
                                        <h4>龔妹</h4>
                                    </td>
                                    <td>
                                        <div className="time">20:50 ~ 21:50</div>
                                        <h2 className="started">Hip-Hop</h2>
                                        <h4>昱豪</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">K-pop Fusion</h2>
                                        <h4>歐陽</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Choreography</h2>
                                        <h4>七七</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">FreeStyle</h2>
                                        <h4>亨利</h4>
                                    </td>
                                    <td><h2 className="started">Twerk</h2>
                                        <h4>碗公</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <img src={imageMap['c-title.png']} className="cTitle" alt='教室位置' />
                        <table width="100%" className="classTable">
                            <colgroup>
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th width="2%">一</th>
                                    <th width="2%">二</th>
                                    <th width="2%">三</th>
                                    <th width="3%">四</th>
                                    <th width="3%">五</th>
                                    <th width="6%"></th>
                                    <th colSpan="2">六</th>
                                    <th colSpan="2">日</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="10" style={{ textAlign: "center", verticalAlign: "middle", padding: "40px 0", fontSize: "15px", lineHeight: "1.6em" }}>
                                        非課程時段<br />
                                        開放場地租借<br />
                                        - 請於上課時段來電洽詢 -
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ClassOne />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="left">
                        <h2><b>代停課</b>公告</h2>
                        <ul>
                            {selectRoomValue.length > 0 ? (
                                selectRoomValue.map((item, index) => (
                                    <li key={`${item.date}-${item.text}-${index}`}>
                                        {formatDateWithDay(item.date)} {item.text}
                                    </li>
                                ))
                            ) : (
                                <li className="noStopClass">目前沒有代停課公告</li>
                            )}
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="level">
                            <li><b className="started">基礎課程</b></li>
                            <li><b className="normal">一般課程</b></li>
                            <li><b className="adv">進階課程</b></li>
                        </ul>
                        <table className="classTable">
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <col width="6.6666%" />
                            <thead>
                                <tr>
                                    <th rowspan="2"></th>
                                    <th colspan="2">一</th>
                                    <th colspan="2">二</th>
                                    <th colspan="2">三</th>
                                    <th colspan="2">四</th>
                                    <th colspan="2">五</th>
                                    <th>&nbsp;</th>
                                    <th colspan="2">六</th>
                                    <th colspan="2">日</th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td>

                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                    <td colspan="2">
                                        <div>A</div>
                                        <div>B</div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td colspan="10" rowspan="2" style={{ textAlign: "center", verticalAlign: "middle", padding: "40px 0", fontSize: "15px", lineHeight: "1.6em" }}>
                                        非課程時段<br />
                                        開放場地租借<br />
                                        - 請於上課時段來電洽詢 -
                                    </td>
                                    <th>16:30<br />
                                        |<br />
                                        18:00</th>
                                    <td>
                                        <h2 className="started">&nbsp;</h2>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td><h2 className="started">Popping</h2>
                                        <h4>小龍女</h4></td>
                                    <td><h2 className="started">Waacking</h2>
                                        <h4>基德</h4></td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th> 18:30<br />
                                        |<br />
                                        20:00 </th>
                                    <td></td>
                                    <td></td>
                                    <td><h2 className="started">Popping</h2>
                                        <h4>龔咩</h4></td>
                                    <td><h2 className="started">Locking</h2>
                                        <h4>雅鈞</h4></td>
                                </tr>
                                <tr>
                                    <th>
                                        18:45<br />|<br />
                                        20:15
                                    </th>
                                    <td>
                                        <h2 className="normal">Hip-Hop</h2>
                                        <h4>Mark</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Afro Fusion</h2>
                                        <h4>Lingo</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Locking</h2>
                                        <h4>小莊</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">基礎律動</h2>
                                        <h4>可白</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">舞感養成</h2>
                                        <h4>蘇蘇</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Hip-Hop</h2>
                                        <h4>昂廷</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Dancehall</h2>
                                        <h4>金瑋瑋</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Hip-Hop</h2>
                                        <h4>Kaku</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">基礎律動</h2>
                                        <h4>咖啡</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Dancehall</h2>
                                        <h4>小姬</h4>
                                    </td>
                                    <th>&nbsp;</th>
                                    <td></td>
                                    <td></td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>20:30<br />|<br />22:00</th>
                                    <td>
                                        <h2 className="normal">House</h2>
                                        <h4>陳學耀</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Heels</h2>
                                        <h4>盈蓁</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">House</h2>
                                        <h4>牛牛</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Hip-Hop</h2>
                                        <h4>亭愷</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">House Grooves</h2>
                                        <h4>JuAnn</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Hip-Hop</h2>
                                        <h4>中中</h4>
                                    </td>
                                    <td>
                                        <h2 className="adv">Popping</h2>
                                        <h4>雪球</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Dancehall</h2>
                                        <h4>涵涵</h4>
                                    </td>
                                    <td>
                                        <h2 className="normal">Afro Fusion</h2>
                                        <h4>巴豪</h4>
                                    </td>
                                    <td>
                                        <h2 className="started">Kpop</h2>
                                        <h4>傲達</h4>
                                    </td>
                                    <th>&nbsp;</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <ClassTwo />
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default Class;
