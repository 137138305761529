import React from 'react';

function ClassTwo() {
    return (
        <table className="mTable">
            <thead>
                <tr>
                    <th colSpan="2">Time</th>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td><i>18:45~20:15</i></td>
                    <td><i>20:30~22:00</i></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th rowSpan="2">一</th>
                    <th>A</th>
                    <td colspan="2" rowspan="10" style={{ textAlign: "center", verticalAlign: "middle", padding: "40px 0", fontSize: "15px", lineHeight: "1.6em" }}>
                        非課程時段<br />
                        開放場地租借<br />
                        - 請於上課時段來電洽詢 -
                    </td>
                    <td><h2 className="normal">Hip-Hop</h2><h4>Mark</h4></td>
                    <td><h2 className="started">House</h2><h4>學耀</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td>
                        <h2 className="started">Afro Fusion</h2>
                        <h4>Lingo</h4>
                    </td>
                    <td>
                        <h2 className="started">Heels</h2>
                        <h4>盈蓁</h4>
                    </td>
                </tr>
                <tr>
                    <th rowSpan="2">二</th>
                    <th>A</th>
                    <td>
                        <h2 className="started">Locking</h2>
                        <h4>小莊</h4>
                    </td>
                    <td><h2 className="started">House</h2><h4>牛牛</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td>
                        <h2 className="started">基礎律動</h2>
                        <h4>可白</h4>
                    </td>
                    <td><h2 className="started">Hip-Hop</h2><h4>亭愷</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">三</th>
                    <th>A</th>
                    <td>
                        <h2 className="started">舞感養成</h2>
                        <h4>蘇蘇</h4>
                    </td>
                    <td><h2 className="started">House Grooves</h2><h4>JuAnn</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">Hip-Hop</h2><h4>昂廷</h4></td>
                    <td><h2 className="started">Hip-Hop</h2><h4>中中</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">四</th>
                    <th>A</th>
                    <td><h2 className="normal">Dancehall</h2><h4>金瑋瑋</h4></td>
                    <td><h2 className="adv">Popping</h2><h4>雪球</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">Hip-Hop</h2><h4>Kaku</h4></td>
                    <td><h2 className="started">Dancehall</h2><h4>涵涵</h4></td>
                </tr>
                <tr>
                    <th rowSpan="2">五</th>
                    <th>A</th>
                    <td><h2 className="started">基礎律動</h2><h4>咖啡</h4></td>
                    <td><h2 className="normal">Afro Fusion</h2><h4>巴豪</h4></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td><h2 className="started">Dancehall</h2><h4>小姬</h4></td>
                    <td><h2 className="started">Kpop</h2><h4>傲達</h4></td>
                </tr>
                <tr>
                    <th rowSpan="3">六</th>
                    <th>&nbsp;</th>
                    <td><i>16:30~18:00</i></td>
                    <td><i>18:30~20:00</i></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>A</th>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th rowSpan="2">日</th>
                    <th>A</th>
                    <td><h2 className="started">Popping</h2>
                        <h4>小龍女</h4></td>
                    <td><h2 className="started">Popping</h2>
                        <h4>龔咩</h4></td>
                    <td>&nbsp;</td>
                    <td></td>
                </tr>
                <tr>
                    <th>B</th>
                    <td>
                    
                    <h2 className="started">Waacking</h2>
                      <h4>基德</h4>
                    </td>
                    <td><h2 className="started">Lonking</h2>
                        <h4>雅鈞</h4></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    );
}

export default ClassTwo;